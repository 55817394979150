import React from 'react';
import { Layout, SEO } from '@componentsShared';
import {RegisterStepThree} from '@components';
import { WrapperRegister } from './styled';

const RegisterContactData = () => {
    return (
        <Layout>
             <SEO
                title="Gana - Datos de contacto"
                index='noindex, nofollow'
                slug="/registro/datos-contacto"
            />
            <WrapperRegister>
                <RegisterStepThree />
            </WrapperRegister>
        </Layout>
    );
};

export default RegisterContactData;
